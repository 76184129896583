import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import get from 'lodash/get'
import React from 'react'
import Button from '../components/common/Button'
import Tags from '../components/common/Tags'
import GeneralCTA from '../components/cta/GeneralCTA'
import Layout from '../components/layout'
import Seo from '../components/seo'
import * as styles from './blog-post.module.css'
class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const plainTextDescription = documentToPlainTextString(
      JSON.parse(post.description.raw)
    )

    const options = {
      renderMark: {
        [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
      },
      renderNode: {
        [INLINES.HYPERLINK]: (node, children) => {
          const { uri } = node.data
          return (
            <a href={uri} className="text-uforange-600">
              {children}
            </a>
          )
        },
        [BLOCKS.HEADING_1]: (node, children) => {
          return <h1 className="font-bold my-4">{children}</h1>
        },
        [BLOCKS.HEADING_2]: (node, children) => {
          return <h2 className="font-bold my-4">{children}</h2>
        },
        [BLOCKS.HEADING_3]: (node, children) => {
          return <h3 className="font-bold my-4">{children}</h3>
        },
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          // find the asset in references by matching the contentful_id
          const asset = post.body.references.find(
            (reference) => reference.contentful_id === node.data.target.sys.id
          )

          if (asset) {
            const image = getImage(asset.gatsbyImageData)
            const alt = node.data.target.description || 'Image for blog post'

            return <GatsbyImage image={image} alt={alt} className="my-4" />
          }
        },
      },
    }

    return (
      <Layout location={this.props.location}>
        <Seo
          title={post.title}
          description={plainTextDescription}
          image={`http:${post.heroImage.resize.src}`}
        />
        <div className="relative overflow-hidden bg-white py-16">
          <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
            <div
              className="relative mx-auto h-full max-w-prose text-lg"
              aria-hidden="true"
            >
              <svg
                className="absolute top-12 left-full translate-x-32 transform"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                />
              </svg>
              <svg
                className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                />
              </svg>
              <svg
                className="absolute bottom-12 left-full translate-x-32 transform"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="d3eb07ae-5182-43e6-857d-35c643af9034"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
                />
              </svg>
            </div>
          </div>
          <div className="relative px-4 sm:px-6 lg:px-8 mx-auto">
            <div className="mx-auto max-w-prose text-lg max-w-2xl mx-auto my-12">
              <h1>
                <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-ufblack sm:text-4xl">
                  {post.title}
                </span>
              </h1>
            </div>
            <GatsbyImage
              alt={post.title}
              image={post.heroImage?.gatsbyImage}
              className="rounded-lg object-cover shadow-lg m-2 h-56 md:h-96 max-w-5xl -mx-10 md:mx-auto"
            />
            <div className="mx-auto my-6 text-gray-500 max-w-2xl ">
              <div className={styles.article}>
                <div className={styles.body}>
                  {post.body?.raw && renderRichText(post.body, options)}
                </div>
              </div>
              {post?.download?.file?.url && (
                <div className="w-full flex justify-center my-2">
                  <Button
                    href={post.download.file.url}
                    label={post.downloadLabel || 'Download file'}
                  />
                </div>
              )}
              <Tags tags={post.tags} />
            </div>
          </div>
          {post.showVideo && (
            <div className="max-w-5xl mx-auto">
              <div className="mx-auto aspect-w-16 mt-16 aspect-h-9 rounded-none md:rounded-xl z-10 overflow-hidden">
                <iframe
                  src="https://www.youtube.com/embed/VeBdA5aHpg8?si=vxOaHbpL95-cQKhI"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Urban Fox demo video"
                ></iframe>
              </div>
            </div>
          )}
          {post.youtubeUrl && (
            <div className="max-w-5xl mx-auto">
              <div className="mx-auto aspect-w-16 mt-16 aspect-h-9 rounded-none md:rounded-xl z-10 overflow-hidden">
                <iframe
                  src={post.youtubeUrl}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Urban Fox demo video"
                ></iframe>
              </div>
            </div>
          )}
        </div>
        <GeneralCTA />
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  ) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      youtubeUrl
      showVideo
      download {
        file {
          url
        }
      }
      downloadLabel
      author {
        name
      }
      publishDate(formatString: "MMMM Do, YYYY")
      rawDate: publishDate
      heroImage {
        gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 1280
            )
            description
          }
        }
      }
      tags
      description {
        raw
      }
    }
    previous: contentfulBlogPost(slug: { eq: $previousPostSlug }) {
      slug
      title
    }
    next: contentfulBlogPost(slug: { eq: $nextPostSlug }) {
      slug
      title
    }
  }
`

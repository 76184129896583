import React from 'react'

const Tags = ({ tags }) =>
  tags?.length > 0 && (
    <div className="flex flex-wrap gap-1 text-sm font-medium text-uforange-600 capitalize">
      {tags.map((tag) => (
        <div key={tag}>{tag}</div>
      ))}
    </div>
  )

export default Tags
